import styled from 'styled-components';
import { Footer, Head, Header, CTA, Grid, Col } from 'components';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Storyblok from 'utils/storyblok';

type Custom404Props = {
  navigation: {
    content: {
      header: any;
      footer: any;
    };
  };
  locale: string;
};

const Custom404 = ({ navigation, locale }: Custom404Props) => {
  const { t } = useTranslation('common');

  const {
    content: { footer, header },
  } = navigation;

  return (
    <>
      <Head title={t('title')} metadescription="Page Description" />
      <main>
        <Header data={header[0]} locale={locale} headerTheme="dark" />
        <StyledSection>
          <Grid container={true} columns={1}>
            <Col>
              <h1>Page Not Found</h1>
              <CTA url="/" text="Home" theme="dark" />
            </Col>
          </Grid>
        </StyledSection>
        <Footer data={footer[0]} />
      </main>
    </>
  );
};

const StyledSection = styled.section`
  height: calc(100vh - 30rem);

  div {
    align-content: center;
  }
`;

export async function getStaticProps({ locale = 'en-us', preview = false }) {
  const sbParams = {
    version: 'published',
    cv: Date.now(),
    resolve_relations: 'reference.reference',
  };

  if (preview) {
    sbParams.version = 'draft';
  }

  const { data: navigation } = await Storyblok.get(`cdn/stories/${locale.toLowerCase()}/global`, sbParams);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      navigation: navigation?.story || null,
      locale,
    },
  };
}

export default Custom404;
